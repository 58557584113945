import React from 'react'
import PropTypes from 'prop-types'
import { Field, useField } from 'react-final-form'

import { Wrapper } from './styles'

import Error from '../Error'

export default function Input({ name, label, required, errors, ...rest }) {
  const {
    meta: { touched, error }
  } = useField(name)

  return (
    <Wrapper>
      {label && (
        <label htmlFor={name} data-required={required}>
          {label}
        </label>
      )}
      <Field {...rest} id={name} name={name} component="textarea" />
      <Error name={name} show={touched && !!error} error={error} />
    </Wrapper>
  )
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  errors: PropTypes.object
}

Input.defaultProps = {
  label: null,
  errors: null
}
