export default function validate(values, schema) {
  const { error } = schema.validate(values, { abortEarly: false })

  const errors = {}

  if (!error) return errors

  if (!error.details) return errors

  error.details.forEach(({ path, message }) => {
    errors[path[0]] = message
  })

  return errors
}
