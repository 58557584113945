import React from 'react'
import PropTypes from 'prop-types'
import { Form as FinalForm, FormSpy } from 'react-final-form'
import createDecorator from 'final-form-focus'

const focusOnError = createDecorator()

function MyFormSpay({ watchValues }) {
  return <FormSpy onChange={({ values }) => watchValues(values)} />
}

export default function Form({ children, watchValues, ...rest }) {
  return (
    <FinalForm
      decorators={[focusOnError]}
      validateOnBlur
      {...rest}
      render={({ handleSubmit }) => (
        <>
          {watchValues && <MyFormSpay watchValues={watchValues} />}
          <form onSubmit={handleSubmit}>{children}</form>
        </>
      )}
    />
  )
}

Form.propTypes = {
  children: PropTypes.node.isRequired
}
