import styled from 'styled-components'

export const ErrorContainer = styled.div`
  padding: 6px;
  margin-top: 2px;
  margin-bottom: 10px;
  background: #fff6f6;
  border-radius: 5px;
  border: 1px solid #9f3a38;
  color: #9f3a38;

  ::before {
    width: 10px;
    height: 10px;
    background: #fff6f6;
    transform: rotate(45deg) translateX(-50%) translateY(-110%);
    position: absolute;
    border-style: solid;
    border-width: 1px 0 0 1px;
    border-color: #9f3a38;
    content: '';
  }
`
