import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

export const style = css`
  cursor: pointer;
  display: ${({ display }) => display || 'inline-block'};
  border-radius: 5px;
  border: 1px solid var(--primary-color);
  padding: 10px 15px;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  float: ${({ right }) => right && 'right'};
  float: ${({ left }) => left && 'left'};

  :hover {
    background: var(--third-color);
    color: var(--second-color);
    border: 1px solid var(--third-color);
  }

  ${({ primary, positive }) =>
    !primary &&
    !positive &&
    css`
      background: var(--second-color);
      color: var(--primary-color);
    `}

  ${({ primary }) =>
    primary &&
    css`
      background: var(--primary-color);
      color: var(--second-color);
    `}

  ${({ positive }) =>
    positive &&
    css`
      background: var(--quaternary-color);
      color: var(--second-color);
      border: 1px solid var(--quaternary-color);

      :hover {
        background: #12de47;
        border: 1px solid #12de47;
      }
    `}
`

export const clickables = {
  a: styled.a`
    ${style}
  `,
  button: styled.button`
    ${style}
  `,
  link: styled(Link)`
    ${style}
  `
}
