function isCellPhoneFormat(value) {
  return /[(][0-9]{2}[)] [0-9]{9}/.test(value)
}

function isPhoneFormat(value) {
  return /[(][0-9]{2}[)] [0-9]{8}[_]/.test(value)
}

export function setPhoneMask(value) {
  if (isCellPhoneFormat(value)) {
    const first = value.slice(0, 10)
    const last = value.slice(10, value.length)

    return `${first}-${last}`
  }

  if (isPhoneFormat(value)) {
    const first = value.slice(0, 9)
    const last = value.slice(9, value.length - 1)

    return `${first}-${last}`
  }

  return value
}
