import styled from 'styled-components'

export const Wrapper = styled.div`
  input {
    &[type='radio'] {
      width: auto;
      margin-left: 0;
    }
  }

  label {
    margin-right: 30px;
  }
`

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`
