import Joi from 'joi'

const schema = Joi.object({
  nome: Joi.string().empty('').required().max(50).messages({
    'any.required': '"Nome" é obrigatório',
    'string.max': 'Máximo de 50 caracteres'
  }),
  email: Joi.string()
    .empty('')
    .required()
    .max(50)
    .email({ tlds: { allow: false } })
    .messages({
      'any.required': '"E-mail" é obrigatório',
      'string.email': 'Precisa ser um endereço de e-mail válido',
      'string.max': 'Máximo de 50 caracteres'
    }),
  telefone: Joi.string()
    .empty('')
    .regex(/[(][0-9]{2}[)] [0-9]{4,5}[-][0-9]{4}/)
    .optional()
    .messages({
      'string.pattern.base': 'Insira um número de telefone válido'
    }),
  assunto: Joi.string().empty('').required().max(100).messages({
    'any.required': '"Assunto" é obrigatório',
    'string.max': 'Máximo de 100 caracteres'
  }),
  mensagem: Joi.string().empty('').required().max(500).messages({
    'any.required': '"Messagem" é obrigatória',
    'string.max': 'Máximo de 500 caracteres'
  })
})

export default schema
