import React from 'react'
import PropTypes from 'prop-types'

import { clickables } from './styles'

const Clickable = ({ as, content, children, ...rest }) => {
  const Tag = clickables[as]

  return <Tag {...rest}>{content || children}</Tag>
}

Clickable.propTypes = {
  as: PropTypes.oneOf(['a', 'button', 'link']),
  primary: PropTypes.bool,
  positive: PropTypes.bool,
  content: PropTypes.string,
  right: PropTypes.bool,
  left: PropTypes.bool
}

export default Clickable
