import React from 'react'
import PropTypes from 'prop-types'
import { Field, useField } from 'react-final-form'

import { Wrapper } from './styles'

import Error from '../Error'

export default function Input({ name, label, required, disabled, ...rest }) {
  const {
    meta: { touched, error }
  } = useField(name)

  return (
    <Wrapper>
      <label htmlFor={name} data-required={required} data-disabled={disabled}>
        {label}
      </label>
      <Field
        {...rest}
        id={name}
        name={name}
        disabled={disabled}
        component="input"
      />
      <Error name={name} show={touched && !!error} error={error} />
    </Wrapper>
  )
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool
}

Input.defaultProps = {
  label: null,
  required: false,
  disabled: false
}
