import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Field, useField } from 'react-final-form'
import InputMask from 'react-input-mask'

import { Wrapper } from './styles'

import Error from '../Error'

export default function Phone({ name, label, required, disabled, ...rest }) {
  const defaultMask = '(99) 999999999'
  const [mask, setMask] = useState(defaultMask)
  const {
    meta: { touched, error }
  } = useField(name)

  function isPhoneFormat(value) {
    return (
      /[(][0-9]{2}[)] [0-9]{8}[_]/.test(value) ||
      /[(][0-9]{2}[)] [0-9]{5}[-][0-9]{3}[_]/.test(value)
    )
  }

  function handleFocus(event, input) {
    setMask(defaultMask)
    input.onFocus(event)
  }

  function handleBlur(event, input) {
    isPhoneFormat(event.target.value)
      ? setMask('(99) 9999-9999')
      : setMask('(99) 99999-9999')
    input.onBlur(event)
  }

  return (
    <Wrapper>
      {label && (
        <label htmlFor={name} data-required={required} data-disabled={disabled}>
          {label}
        </label>
      )}
      <Field
        name={name}
        render={({ input }) => (
          <InputMask
            {...rest}
            {...input}
            id={name}
            disabled={disabled}
            mask={mask}
            onFocus={event => handleFocus(event, input)}
            onBlur={event => handleBlur(event, input)}
            type="tel"
          />
        )}
      />
      <Error show={touched && !!error} error={error} />
    </Wrapper>
  )
}

Phone.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool
}

Phone.defaultProps = {
  label: null,
  required: false,
  disabled: false,
  mask: null
}
