import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import ContactForm from '../components/ContactForm'
import { SITE_KEY } from '../util/constants'
import { Wrapper } from '../styles/layout'
import Header from '../styles/Header'

export default function Contato() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={SITE_KEY}>
      <Layout>
        <Seo title="Contato" />
        <Wrapper>
          <Header as="h1" textAlign="center" content="Contato" />
          <ContactForm />
        </Wrapper>
      </Layout>
    </GoogleReCaptchaProvider>
  )
}
