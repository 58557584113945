import React, { useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { Wrapper } from './styles'
import messages from './messages'
import schema from './schema'

import { Form, Input, Phone, TextArea, validate } from '../../Form'
import { setPhoneMask } from '../../../util/phone'
import Clickable from '../../Clickable'
import api from '../../../services/api'

export default function CustomForm() {
  const [loading, setLoading] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha()

  function validation(values) {
    values.telefone = setPhoneMask(values.telefone)

    return validate(values, schema)
  }

  function handleSubmit(values, form) {
    setLoading(true)
    values.telefone = setPhoneMask(values.telefone)

    function onSuccess() {
      setTimeout(form.restart)
      alert(messages.successMessageSend)
    }

    executeRecaptcha('contato')
      .then(token => {
        api
          .post('/email', { ...values, formulario: 'contato', token })
          .then(() => onSuccess())
          .catch(() => alert(messages.failedMessageSend))
      })
      .catch(() => alert(messages.failedRecaptchaValidate))
      .finally(() => setLoading(false))
  }

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit} validate={validation}>
        <Input
          name="nome"
          label="Nome"
          placeholder="João da Silva"
          maxLength={50}
          disabled={loading}
          required
        />
        <Input
          name="email"
          label="E-mail"
          placeholder="joaodasilva@dominio.com.br"
          maxLength={50}
          disabled={loading}
          required
          type="email"
        />
        <Phone
          name="telefone"
          label="Telefone"
          placeholder="(99) 99999-9999"
          disabled={loading}
        />
        <Input
          name="assunto"
          label="Assunto"
          placeholder="Sugestão"
          maxLength={100}
          disabled={loading}
          required
        />
        <TextArea
          name="mensagem"
          label="Mensagem"
          maxLength={500}
          disabled={loading}
          rows={6}
          required
        />
        <Clickable as="button" primary right type="submit" disabled={loading}>
          Enviar
        </Clickable>
      </Form>
    </Wrapper>
  )
}
