import React from 'react'
import PropTypes from 'prop-types'

import { ErrorContainer } from './styles'

export default function Error({ show, error }) {
  return show ? (
    <ErrorContainer>
      <span>{error}</span>
    </ErrorContainer>
  ) : null
}

Error.propTypes = {
  show: PropTypes.bool.isRequired,
  error: PropTypes.string
}

Error.defaultProps = {
  error: ''
}
