import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: 30px auto;
  max-width: 500px;

  form {
    margin-bottom: 60px;
  }
`
